import { all, call, put, select, takeEvery } from "redux-saga/effects";
import sliceName from "../../../states/sliceName.state";
import { CustomPayload, apiResponse } from "../../../states/interface.state";
import ChatbotDetailsService from "../services/helpers/ChatbotDetails.service";
import { getIntegrationDataFailed, getIntegrationDataSuccess, isGettingDetailsFailed, isGettingDetailsSuccess, storeDetailsDataFailed, storeDetailsDataSuccess } from "./chatbotDetails.slice";
import { chatbotDetailsStates } from "../../../states/allSelector.state";
import Chatbot from "../../../controllers/models/Chatbot.model";

function* chatbotDetails() {
    yield takeEvery(`${sliceName.chatbotDetails}/storeId`, changeTabSaga);
    yield takeEvery(`${sliceName.chatbotDetails}/changeTab`, changeTabSaga);
    yield takeEvery(`${sliceName.chatbotDetails}/storeDetailsData`, storeDetailsDataSaga);
    yield takeEvery(`${sliceName.chatbotDetails}/getIntegrationData`, getIntegrationDataSaga);
}

function* changeTabSaga(): Generator {
    const { tab, id }: any = yield select(chatbotDetailsStates);
    if(tab !== Chatbot.DETAILS_TAB_INTEGRATION){
        const result = yield call(ChatbotDetailsService.getChatbotTabDetails, { tab: tab, id: id });
        if (!result) {
            yield put(isGettingDetailsFailed());
            return;
        }
        const { body, status } = result as apiResponse;
        if (status) {
            yield put(isGettingDetailsSuccess(body));
        } else {
            yield put(isGettingDetailsFailed());
    }
    }
}
function* storeDetailsDataSaga({ payload }: CustomPayload<any>): Generator {
    const { tab, id }: any = yield select(chatbotDetailsStates);
    const result = yield call(ChatbotDetailsService.updateChatbotTabDetails, { ...payload, tab: tab, id: id });
    if (!result) {
        yield put(storeDetailsDataFailed());
        return;
    }
    const { status } = result as apiResponse;
    if (status) {
        yield put(storeDetailsDataSuccess(payload));
    } else {
        yield put(storeDetailsDataFailed());
    }
}
function* getIntegrationDataSaga({ payload }: CustomPayload<any>): Generator {
    const { id }: any = yield select(chatbotDetailsStates);
    const result = yield call(ChatbotDetailsService.getChatbotIntegrationData, { id: id });
    if (!result) {
        yield put(getIntegrationDataFailed());
        return;
    }
    const { status, body } = result as apiResponse;
    if (status) {
        yield put(getIntegrationDataSuccess(body));
    } else {
        yield put(getIntegrationDataFailed());
    }
}

export default function* chatbotDetailsSaga() {
    yield all([chatbotDetails()]);
}
