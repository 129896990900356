import { createSlice } from "@reduxjs/toolkit";
import sliceName from "../../../states/sliceName.state";
import { initialStateP, reducerFunctions } from "./interface.s.m";
import { CustomAction } from "../../../states/interface.state";
import { setPublicConversationList } from "../../../helper/cookie/cookie";

const initialState: initialStateP = {
    id: '',
    code: '',
    token: '',
    item: null,
    isGetting: true,
    hasFailedMessage: '',
    /* for conversation */
    conversations: [],
    /* new message send */
    message: "",
    images: [],
    isSending: false,
    clearLocalStore: true
}

const publicViewSlice: any = createSlice({
    name: sliceName.publicView,
    initialState: initialState,
    reducers: {
        getDetailsById: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.isGetting = true;
            state.id = payload.id;
        },
        getDetailsByCode: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.isGetting = true;
            state.code = payload.code;
        },
        getDetailsSuccess: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.item = payload;
            state.token = payload?.uniqueConversationCode || '';
            state.id = payload?.id || '';
            state.isGetting = false;
            state.hasFailedMessage = '';
        },
        getDetailsFailed: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.isGetting = false;
            state.hasFailedMessage = payload;
        },
        storeSendData: (state: initialStateP, { payload }: CustomAction<any>) => {
            (state as any)[payload.name] = payload.value;
        },
        createNewMessage: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.isSending = true;
            state.clearLocalStore = !state.clearLocalStore;
            const outMessage = {
                message: payload.message,
                files: payload.files,
                images: payload.images,
                localData: true,
                created_at: new Date().toISOString(),
                inOut: 2, //may be change later 1 = out
            };
            const _new_list = [...state.conversations, outMessage];
            state.conversations = [..._new_list];
            setPublicConversationList([..._new_list], state.code)
        },
        createNewMessageSuccess: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.message = "";
            state.images = [];
            const inMessage = {
                message: payload.botText,
                localData: false,
                created_at: new Date().toISOString(),
                inOut: 1
            };
            const _new_list = [...state.conversations, inMessage];
            state.conversations = [..._new_list];
            state.isSending = false;
            state.clearLocalStore = !state.clearLocalStore;
            setPublicConversationList([..._new_list], state.code)
        },
        createNewMessageFailed: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.isSending = false;
        },
        storePreviewItem: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.item = { ...payload };
            state.isGetting = false;
        },
        restoreConversation: (state: initialStateP, { payload }: CustomAction<any>) => {
            state.conversations = [...payload];
        },
    },
});

export const {
    getDetailsById,
    getDetailsByCode,
    getDetailsSuccess,
    getDetailsFailed,
    storeSendData,
    createNewMessage,
    createNewMessageSuccess,
    createNewMessageFailed,
    storePreviewItem,
    restoreConversation
}: reducerFunctions = publicViewSlice.actions;

export default publicViewSlice.reducer;
