import rootApi from "../../../../controllers/api/root-api-handler/rootApi.api";
// import dummyData from "../../../../controllers/dummy-data/dummyData";
// import { dummyDataFormat } from "../../../../controllers/dummy-data/dummyDataFormat";
// import { sleepHook } from "../../../../helper/hooks/sleep.hook";
import authEndPoint from "../apiEndPoint.service.m";
import { checkEmailForRegProps, loginProps } from "../interface.service.m";

class AuthServiceClass {
    async tryLogin(payload: loginProps) {
        return rootApi('POST_WITHOUT_AUTH', authEndPoint.tryLogin, payload);
    }
    async getProfileData() {
        // if (process.env.REACT_APP_MODE !== 'PRODUCTION') {
        //     await sleepHook(() => { }, 3000);
        //     /* need to change here */
        //     const object = {
        //         ...dummyData.chatbot['chatbot/delete-item']
        //     };
        //     return dummyDataFormat(object);
        // }
        return rootApi('GET', authEndPoint.getProfileData);
    }
    async checkEmailExist(payload: checkEmailForRegProps) {
        return rootApi('POST_WITHOUT_AUTH', authEndPoint.isRegEmailIsExist, payload);
    }
    async tryRegistration(payload: checkEmailForRegProps) {
        return rootApi('POST_WITHOUT_AUTH', authEndPoint.tryRegistration, payload);
    }
}

const AuthService = new AuthServiceClass();
export default AuthService;