import rootApi from "../../../../controllers/api/root-api-handler/rootApi.api";
import leadEndPoint from "../apiEndPoint.service.m";

class LeadServiceClass {
    async getList(payload: any) {
        let _url = leadEndPoint.getList;
        if(payload){
            _url = _url + "?chatbot_id=" + payload.chatbot_id;
        }
        return rootApi('GET', _url);
    }
}

const LeadService = new LeadServiceClass();
export default LeadService;