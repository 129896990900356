import rootApi from "../../../../controllers/api/root-api-handler/rootApi.api";
import publicViewEndPoint from "../apiEndPoint.service.m";
import { creteNewConversationProps, getDetailsProps } from "../interface.service.m";

const getPythonData = async(payload: any) => {
    return await fetch("https://softwareelevate.com/chatbot_p/fetch", {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        referrerPolicy: 'origin',
        body: JSON.stringify(payload),
    })
    .then((response) => response.json())
    .then((responseData) => {
        return responseData;
    })
    .catch((_) => {
        return null;
    });
}
class PublicViewServiceClass {
    async getPublicViewDetails(payload: getDetailsProps) {
        return rootApi('GET_WITHOUT_AUTH', publicViewEndPoint.getPublicViewDetails + payload.id);
    }
    async createNewConversation(payload: creteNewConversationProps) {
        const response = await getPythonData({
            "text": payload.message
        });
        if(response){
            return rootApi('POST_FROM_DATA_WITHOUT_AUTH', publicViewEndPoint.createNewConversation, {...payload, pythonData: response});
        }
        else{
            return rootApi('POST_FROM_DATA_WITHOUT_AUTH', publicViewEndPoint.createNewConversation, {...payload, pythonData: {}});
        }
        
    }
    
}

const PublicViewService = new PublicViewServiceClass();
export default PublicViewService;