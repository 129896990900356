import rootApi from "../../../../controllers/api/root-api-handler/rootApi.api";
import chatbotEndPoint from "../apiEndPoint.service.m";

class ConversationServiceClass {
    async getConversationUniqueList(payload: any) {
        let _url = chatbotEndPoint.getConversationUniqueList + "?page=" + payload.page;
        if(payload.chatbot_id){
            _url = _url + "&chatbot_id=" + payload.chatbot_id;
        }
        return rootApi('GET', _url);
    }
}

const ConversationService = new ConversationServiceClass();
export default ConversationService;