import rootApi from "../../../../controllers/api/root-api-handler/rootApi.api";
// import dummyData from "../../../../controllers/dummy-data/dummyData";
// import { dummyDataFormat } from "../../../../controllers/dummy-data/dummyDataFormat";
// import { sleepHook } from "../../../../helper/hooks/sleep.hook";
import dashboardEndPoint from "../apiEndPoint.service.m";

class DashboardServiceClass {
    async getStats(payload: any) {
        // if (process.env.REACT_APP_MODE !== 'PRODUCTION') {
        //     await sleepHook(() => { }, 2000);
        //     const object = {
        //         ...dummyData.dashboard['dashboard/get-stats']
        //     };
        //     return dummyDataFormat(object);
        // }
        return rootApi('POST', dashboardEndPoint.getStats, payload);
    }
}

const DashboardService = new DashboardServiceClass();
export default DashboardService;