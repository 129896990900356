import styles from '../css/top-bar.layout.module.css';

const { logoWrp, brand, title } = styles

const AuthTopBar = () => {
    return (
        <div className={logoWrp}>
            <div className={`${brand}`}>
                <img src='/assets/images/logo.png' alt='SE-TAi' />
                <div className={`${title}`}>
                    <span className='input_sub_label'>Auto SMS </span>
                    <span className='input_sub_label'>Agent</span>
                </div>
            </div>
        </div>
    )
};

export default AuthTopBar;