import { baseURL, moduleName } from "../../../controllers/api/globalEndpoint.api";

const chatbotDetailsEndPoint = {
    getChatbotDetails: baseURL + moduleName.auth + moduleName.chatbot + '/get-details',
    updateChatbotSetupDetails: baseURL + moduleName.auth + moduleName.chatbot + '/update-setup-details',
    updateChatbotInstructionDetails: baseURL + moduleName.auth + moduleName.chatbot + '/update-instructions-details',
    updateChatbotActionsDetails: baseURL + moduleName.auth + moduleName.chatbot + '/update-actions-details',
    /* integration */
    getChatbotIntegration: baseURL + moduleName.auth + moduleName.chatbot + '/get-webhook',
    updateChatbotIntegration: baseURL + moduleName.auth + moduleName.chatbot + '/update-webhook',
};

export default chatbotDetailsEndPoint;