const options: any = {
    timeZone: 'Asia/Shanghai',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
};


const getCurrentDateTimeMilliSeconds = () => {
    const myDate = new Date();
    return myDate.getTime();
};

const convertTimeToMDYWithTimezone = (time: string, timeZone = 'Asia/Shanghai') => {
    try{
        const date = new Date(time);
        options['timeZone'] = timeZone;
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const formattedDateParts = formatter.formatToParts(date);
        let formattedDate = "";
        formattedDateParts.forEach(part => {
            if (part.type === 'month' || part.type === 'day' || part.type === 'year') {
                formattedDate += part.value + "-";
            } else if (part.type === 'hour' || part.type === 'minute' || part.type === 'second') {
                formattedDate += part.value + ":";
            }
        });
        formattedDate = formattedDate.slice(0, -1);
        return formattedDate;
    }
    catch(e){
        return time;
    }
};

export { getCurrentDateTimeMilliSeconds, convertTimeToMDYWithTimezone };
