import { chatbotDetailsTab } from "../../modules/chatbot-details/states/interface.s.m";

type greeting_type_props = 1 | 2;
type status_props = 0 | 1 | '0' | '1' | 'ACTIVE' | 'INACTIVE';

class ChatbotModel {
    STATUS_INACTIVE = 'INACTIVE';
    STATUS_ACTIVE = 'ACTIVE';

    DETAILS_TAB_SETUP: chatbotDetailsTab = 'setup';
    DETAILS_TAB_INSTRUCTION: chatbotDetailsTab = 'instructions';
    DETAILS_TAB_ACTIONS: chatbotDetailsTab = 'actions';
    DETAILS_TAB_STYLE: chatbotDetailsTab = 'style';
    DETAILS_TAB_CONVERSATION: chatbotDetailsTab = 'conversation';
    DETAILS_TAB_INTEGRATION: chatbotDetailsTab = 'integration';

    INTEGRATION_WEBHOOK_TYPE_PABBLY = '4';
    INTEGRATION_WEBHOOK_TYPE_ZAPIER = '5';

    GREETING_TYPE_STATIC = 1;
    GREETING_TYPE_GENERIC = 2;

    SHOW_DISCLOSURE_TRUE = true;
    SHOW_DISCLOSURE_FALSE = false;

    status_title = {
        [this.STATUS_ACTIVE]: 'Active',
        [this.STATUS_INACTIVE]: 'Inactive',
    }

    greeting_type_title = {
        [this.GREETING_TYPE_STATIC]: 'Static',
        [this.GREETING_TYPE_GENERIC]: 'Generic',
    }

    getStatus = (status: status_props) => {
        return this.status_title[status];
    }
    getGreetingType = (type: greeting_type_props) => {
        return this.greeting_type_title[type];
    }

}
const Chatbot = new ChatbotModel();
export default Chatbot;