import rootApi from "../../../../controllers/api/root-api-handler/rootApi.api";
import Chatbot from "../../../../controllers/models/Chatbot.model";
import chatbotDetailsEndPoint from "../apiEndPoint.service.m";
import { getChatbotTabDetailsProps } from "../interface.service.m";

class ChatbotDetailsServiceClass {
    async getChatbotTabDetails(payload: getChatbotTabDetailsProps) {
        const url = `${chatbotDetailsEndPoint.getChatbotDetails}?id=${payload.id}&tab=${payload.tab}`
        return rootApi('GET', url, payload);
    }
    async updateChatbotTabDetails(payload: any) {
        const { tab } = payload;
        let url = "";
        if (tab === Chatbot.DETAILS_TAB_SETUP) { url = chatbotDetailsEndPoint.updateChatbotSetupDetails }
        else if (tab === Chatbot.DETAILS_TAB_INSTRUCTION) { url = chatbotDetailsEndPoint.updateChatbotInstructionDetails }
        else if (tab === Chatbot.DETAILS_TAB_ACTIONS) { url = chatbotDetailsEndPoint.updateChatbotActionsDetails }
        return rootApi('POST', url, payload);
    }
    async getChatbotIntegrationData(payload: any) {
        const url = `${chatbotDetailsEndPoint.getChatbotIntegration}?id=${payload.id}`
        return rootApi('GET', url, payload);
    }
    async updateChatbotIntegrationData(payload: any) {
        return rootApi('POST', chatbotDetailsEndPoint.updateChatbotIntegration, payload);
    }
}

const ChatbotDetailsService = new ChatbotDetailsServiceClass();
export default ChatbotDetailsService;