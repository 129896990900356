import consoleRouteName from "../../router/consoleRouteName.router";

export interface menuProps {
    title: string;
    icon?: any;
    value: string | number;
    isHide?: boolean;
    isDisable?: boolean;
    style?: any;
    extra?: any;
    accessRole?: number | string;
    link?: string;
}

const leftSideBarMenu: Array<menuProps> = [
    {
        title: 'Dashboard',
        value: 'dashboard',
        isHide: true,
        link: consoleRouteName.console.path
    },
    {
        title: 'Chatbot',
        value: 'chatbot',
        isHide: false,
        link: consoleRouteName.chatbot.path
    },
    {
        title: 'Conversations',
        value: 'conversation',
        isHide: false,
        link: consoleRouteName.conversation.path
    },
    {
        title: 'Leads',
        value: 'lead',
        isHide: false,
        link: consoleRouteName.leadList.path
    },
    {
        title: 'Company Information',
        value: 'company',
        isHide: false,
        link: consoleRouteName.company.path
    },
    {
        title: 'Subscription',
        value: 'subscription',
        isHide: false,
        link: consoleRouteName.subscription.path
    },
    {
        title: 'Billing',
        value: 'credit',
        isHide: false,
        link: consoleRouteName.credit.path
    },
    // {
    //     title: 'Profile',
    //     value: 'profile',
    //     isHide: false,
    //     link: consoleRouteName.profile.path
    // },
];

export { leftSideBarMenu };