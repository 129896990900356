import { Route, Routes } from "react-router-dom";
import NoRouteFound from "./suspense-fallback/NoRouteFound";
import renderConsolePrivateRoutes from "./console-private-routes.route";
import Loadable from "./suspense-fallback/Loadable.suspense";
import { lazy } from "react";
import ChatbotPublicViewFallback from "./suspense-fallback/ChatbotPublicView.fallback";
import consoleRouteName from "./consoleRouteName.router";

const LoaderView = () => {
    return (
        <div id="page_root_loader">
            <span className="loader"></span>
        </div>
    );
};

const LoginIndex = Loadable(lazy(() => import('../modules/auth/Index.m')));
const RegistrationIndex = Loadable(lazy(() => import('../modules/auth/IndexRegistration.m')));
const ChatbotPublicView = Loadable(lazy(() => import('../modules/chatbot-public-view/Index.m')), ChatbotPublicViewFallback);
const ChatbotPublicViewEmbed = Loadable(lazy(() => import('../modules/chatbot-public-view/IndexEmbed.m')), ChatbotPublicViewFallback);
const ChatbotPublicViewIframe = Loadable(lazy(() => import('../modules/chatbot-public-view/IndexIframe.m')), ChatbotPublicViewFallback);
const PublicPackageIndex = Loadable(lazy(() => import('../modules/packages/Index.m')));
const ForceLoginIndexM = Loadable(lazy(() => import('../modules/auth/ForceLoginIndex.m')), LoaderView);

const ProjectRouter = () => {
    return (
        <Routes>
            {renderConsolePrivateRoutes()}
            {/* authenticate routes */}
            <Route path="/login" element={<LoginIndex />} />
            <Route path="/registration" element={<RegistrationIndex />} />
            <Route path={consoleRouteName.publicPackage.path} element={<PublicPackageIndex />} />
            {/* public route */}
            <Route path="/public-view/chat-bot/:key" element={<ChatbotPublicView />} />
            <Route path="/public-view/chat-bot-embed/:key" element={<ChatbotPublicViewEmbed />} />
            <Route path="/public-view/chat-bot-iframe/:key" element={<ChatbotPublicViewIframe />} />
            <Route path="/o-auth" element={<ForceLoginIndexM />} />
            <Route path="*" element={<NoRouteFound />} />
        </Routes>
    )
};

export default ProjectRouter;