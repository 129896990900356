import { baseURL, moduleName } from "../../../controllers/api/globalEndpoint.api";

const packageEndPoint = {
    getPackageList: baseURL + moduleName.package + '/list',
    getUserPackage: baseURL + '/user/has/package/check/data',
    buyPackage: baseURL + moduleName.package + '/buy',
    creditBalance: baseURL + '/user/balance/key/wise/data',
    getInvoiceData: baseURL + moduleName.invoice + '/get/all/data',
    getTopUpData: baseURL + moduleName.recharge + '/get/amount/data',
    submitTopUp: baseURL + moduleName.recharge + '/balance',
    /* auto recharge */
    getAmountData: baseURL + moduleName.recharge + '/get/amount/data',
    getChargeAmountData: baseURL + moduleName.recharge + '/get/charge/amount/data',
    submitAutoRecharge: baseURL + moduleName.recharge + '/balance',
    getCardList: baseURL + moduleName.payment + '/card/list',
    addNewCard: baseURL + moduleName.payment + '/new/card/add',
    cardMarkAsDefault: baseURL + moduleName.payment + '/update/card/setup/as/default',
    deleteCard: baseURL + moduleName.payment + '/delete/card',
    /* credit balance */
    currentCredit: baseURL + moduleName.balance + '/key/wise/data',
};

export default packageEndPoint;