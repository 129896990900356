import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import configStore from './states/store';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ProjectRouter from './router/ProjectRouterIndex.route';
import { Toaster } from 'react-hot-toast';

// const pathName = window.location.pathname;
/* we can implement  another router and view id for public view */
const root = ReactDOM.createRoot(
    document.getElementById('elevate-talk-ai-user') as HTMLElement
);

export const __constant = {
    publicChatbotId: null
};

root.render(
    <BrowserRouter>
        <Provider store={configStore}>
            <ProjectRouter />
            <Toaster position="top-right" />
        </Provider>
    </BrowserRouter>
);
reportWebVitals();
